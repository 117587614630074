.container-disclaimer {
    .header {
        .text {
            color: #5b5b5b;
            font-weight: 700;
            letter-spacing: -.03em;
        }
    }

    .body {

        color: #6d6b6b;

        .media {
            padding: 1rem 0;
            font-size: 14px;
        }
    }
}