/* Estilos generales para pantallas grandes */
$main-color: #70c000;

.account {
    background-color: #fff;
    border-radius: 15px;
    box-shadow: 2px 4px 8px rgba(0, 0, 0, 0.1);
    overflow: hidden;
    transition: transform 0.3s, box-shadow 0.3s;
    box-sizing: border-box;

    &:hover {
        cursor: pointer;
    }

    &-separator {
        border-bottom: 1px solid $main-color;
    }

    &-separator:last-child {
        border-bottom: none;
    }

    &-type {
        padding: 20px 20px 0;
        border-left: 8px solid $main-color;
        position: relative;
        margin-bottom: 0;
        box-sizing: border-box;
        min-height: 200px;

        h3 {
            margin: 0 0 10px;
            font-size: 14px;
            color: #333;
        }

        .account-code {
            font-weight: bold;
            color: $main-color;
        }

        p {
            margin: 4px 0;
            color: #555;
            font-size: 16px;
        }

        .text-small {
            font-size: 14px;
            font-style: italic;
        }
    }

    &-green-border {
        padding: 10px;
        border: 2px solid $main-color;
        border-radius: 15px;
        min-height: 160px;
        box-sizing: border-box;
    }

    .account-icon {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .account-title {
        padding: 0 0px;
    }

    .estado {
        font-size: 10px;
        position: relative;
        float: left;
        width: 100%;
        text-align: center;
        margin-top: 10px;
        margin-bottom: 20px;
        color: #5B5B5B !important;
    }
}

/* Media Queries para pantallas medianas y pequeñas */
@media (max-width: 768px) {
    .account {
        &-title {
            padding: 0 0px;
            margin: 10px auto;
        }

        .cadenas_22 {
            flex-direction: column;
        }
    }
}

.center-content {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;    
}

.text-grayy {
    color: #5B5B5B;
    font-weight: 700;
    letter-spacing: -0.03em;
    font-family: 'Poppins', sans-serif !important;
    margin-top: 15px;

    @media (min-width: 1024px) {
        font-size: 28px;
    }
}

.fondo-box {
    border-radius: 5px;
    border: 0.5px solid #ffffff;
    font-size: 13px;
    background-color: #fff;
    font-family: 'Poppins', sans-serif !important;
    margin-top: 15px;

    &:hover {
        transform: translateY(-10px);
        cursor: pointer;
    }

    &-header {
        padding: 5px;
        border-top-left-radius: 5px;
        border-top-right-radius: 5px;
    }

    &-titulo {
        height: 14px;
    }

    &-letra {
        font-size: 30px;
        font-weight: 500;
        line-height: 40px;
    }

    &-body {
        padding: 10px;
        font-size: 12px !important;
        color: #fff;
        text-align: center;
        border-bottom-left-radius: 5px;
        border-bottom-right-radius: 5px;
        min-height: 270px;
        max-height: 390px;

        @media (max-width: 575px),
        (min-width: 575px) {

            .fondo-riesgo,
            .fondo-titulo {
                font-size: 40px !important;
            }

            .fondo-letra {
                font-size: 50px !important;
            }

            .fondo-box-body {
                font-size: 25px !important;
            }
        }

        @media (min-width: 768px) {

            .fondo-riesgo,
            .fondo-titulo {
                font-size: 20px !important;
            }

            .fondo-letra {
                font-size: 30px !important;
            }

            .fondo-box-body {
                font-size: 16px !important;
            }
        }

        @media (min-width: 992px) {

            .fondo-riesgo,
            .fondo-titulo {
                font-size: 12px !important;
            }

            .fondo-letra {
                font-size: 16px !important;
            }

            .fondo-box-body {
                font-size: 12px !important;
            }
        }

        @media (min-width: 1200px) {

            .fondo-riesgo,
            .fondo-titulo {
                font-size: 12px !important;
            }

            .fondo-letra {
                font-size: 16px !important;
            }

            .fondo-box-body {
                font-size: 12px !important;
            }
        }

        @media (min-width: 1400px) {

            .fondo-riesgo,
            .fondo-titulo {
                font-size: 12px !important;
            }

            .fondo-letra {
                font-size: 16px !important;
            }

            .fondo-box-body {
                font-size: 12px !important;
            }
        }
    }
}