$principal: #4297de;

.bg-blue {
    width: 100%;
    background-repeat: repeat;
    position: relative;
    background-color: $principal;
    background-image: url("../../assets/svg/bg-blue.svg");
    background-position: center;
    border: 0;
    float: left;
    margin-bottom: -20px;
    height: 100%;
}

.img-bienvenida {
    display: none;
}

.container-bienvenida {
    margin: 20px;

    @media (min-width: 1024px) {
        width: 500px;
        padding-right: 15px;
        padding-left: 15px;
        margin-right: auto;
        margin-left: auto;
    }
}

.media-white {
    padding: 30px !important;
    background-color: #fff !important;
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25) !important;
    border-radius: 12px !important;
    color: #5b5b5b !important;
    font-size: 16px;
    line-height: 24px;

    h2 {
        font-weight: bold;
        font-size: 36px;
        line-height: 54px;
        letter-spacing: -0.03em;
        color: #5b5b5b;
    }

    p {
        color: #5b5b5b !important;
        font-size: 16px;
        line-height: 24px;
    }
}

.corazon {
    width: auto;
    height: auto;
    position: absolute;
    top: 30px;
    right: 30px;
}

.redes {
    width: 165px;
    padding: 0 15px;
    margin: 0 auto;

    img {
        padding: 0 10px;
    }
}

@media (min-width: 1024px) {
    .img-bienvenida {
        position: absolute;
        right: 0;
        top: 40px;
        display: block;

        img {
            width: 377px;
        }
    }
}