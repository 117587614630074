$main-color: #70c000;

.footer-container {
	width: 100%;
	display: flex;
	justify-content: center;
	text-align: justify;
	color: #5b5b5b;
	background-color: #ededed;

	.footer-inner-container {
		margin-top: 10px;
		width: 80%;
		display: flex;
		padding: 20px;

		.footer-text-container {
			display: flex;
			justify-content: center;
			flex-direction: column;

			.footer-text {
				font-size: 14px;
				line-height: 20px;
				margin-bottom: 10px;

				a {
					color: $main-color;
				}
			}
		}
	}

	.footer-copyright {
		text-align: center;
		margin-top: 35px;
		font-size: 14px;
	}
}