.modelo-popover {
    background-color: #323639;
    display: inline-block;
    font-weight: 600;
    position: relative;
    left: -100px;
    max-width: 400px;
}

.modelo-popover>.tooltip-content {
    color: #ffffff;
}

.modelo-popover>.arrow:after {
    border-top-color: #323639;
}