$main-color: #70c000;
$warning: #ffc107;
$danger: #dc3545;

.container-timer {
    margin: 0 auto 10px;
    font-size: 18px;
    border-radius: 5px;
    text-align: center;
    width: 130px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.container-timer-success {
    border: 2px solid $main-color;
}

.container-timer-warning {
    border: 2px solid $warning;
}

.container-timer-danger {
    border: 2px solid $danger;
}

.container-timer>div {
    display: flex;
    align-items: center;
}

.container-timer img {
    margin-left: 8px;
}