.confirmar {
    &.green-box {
        padding: 10px;
        margin: 10px;
        border: 2px solid rgb(114, 181, 0);
        border-radius: 15px;
        position: relative;
        float: left;
        width: 96%;
        min-height: 198px;
        font-size: 14px;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    }

    .cuenta {
        font-weight: bold;
        font-size: 18px;
        line-height: 30px;
        align-items: center;
        letter-spacing: -0.03em;
        color: #424242;
    }

    .destino {
        font-size: 19px;
        line-height: 30px;
        display: flex;
        align-items: center;
        letter-spacing: -0.03em;
        color: #5B5B5B;
    }

    .origen {
        font-size: 14px;
    }

    .saldo {
        font-size: 14px;
        line-height: 30px;
        display: flex;
        align-items: center;
        letter-spacing: -0.03em;
        color: #5B5B5B;
    }

    .porcentaje {
        font-size: 13px;
    }

    .monto {
        font-weight: bold;
        font-size: 14px;
        line-height: 21px;
        letter-spacing: -0.06em;
        color: #5B5B5B;
        margin-top: 20px;
        padding-top: 16px;
        width: 100%;
        position: relative;
        float: left;
    }
}

.text-muted {
    color: #6c757d !important;
    font-size: 13px;
}

.custom-modal-dialog {
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    height: 100vh !important;
    z-index: 10001 !important;
}

.custom-modal-content {
    width: 200px;
}