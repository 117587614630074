// Variables
$background-primary-color: #212529;
$background-secondary-color: #70c000;
$green-logo: "../../assets/svg/logo.svg";
$white-logo: "../../assets/svg/modelo-blanco.svg";

// Navbar styles
.navbar {
	background-color: $background-primary-color;

	.container {
		height: 75px;

		a {
			color: #fafafa;
		}
	}

	// Media queries for different screen sizes
	@media (max-width: 575px) {
		background-color: $background-secondary-color;

		.logo {
			top: 25px;
			background-image: url($white-logo);
		}

	}

	@media (min-width: 576px) and (max-width: 767px) {
		background-color: $background-secondary-color;

		.logo {
			top: 25px;
			background-image: url($white-logo);
		}
	}

	@media (min-width: 768px) and (max-width: 1199px) {
		background-color: $background-secondary-color;

		.logo {
			top: 18px;
			background-image: url($white-logo);
		}
	}

	@media (min-width: 1200px) {
		background-color: $background-primary-color;

		.logo {
			top: 18px;
			background-image: url($green-logo);
		}
	}

	@media (min-width: 1400px) {
		.logo {
			top: 18px;
			background-image: url($green-logo);
		}
	}

	.logo {
		background-repeat: no-repeat;
		position: absolute;
		left: 55px;
		width: 134px;
		height: 80px;
	}
}

@function random-color() {
	$colors: #ee0202, #ffc145, #652f07, #30a7a7, #81bd00, #800080;
	$random-index: random(length($colors));
	//$random-index: 1;
	@return nth($colors, $random-index);
}

.color-avatar {
	color: #fafafa !important;
	background-color: random-color() !important;
}

.b {
	color: #ffffff;
	//width: 50%;
	text-align: right;
}

.success {
	background-color: $background-secondary-color !important;
}