.home-container {
    border: 1px solid #918e8e;
    border-radius: 10px;
    padding: 20px;
    width: 15px; // Ajusta este valor si es necesario
    margin: 0 auto;

    @media (max-width: 575.98px) {
        width: 100%;
    }

    @media (min-width: 576px) {
        width: 100%;
    }

    @media (min-width: 768px) {
        width: 350px;
    }

    @media (min-width: 992px) {
        width: 350px;
    }

    @media (min-width: 1200px) {
        width: 350px;
    }

    @media (min-width: 1400px) {
        width: 350px;
    }
}