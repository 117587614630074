.autentication {
    .sub-title {
        color: #6d6b6b;
        font-size: 18px;
        font-weight: 600;
        font-family: "Poppins", sans-serif !important;
    }

    .identificacion-icon {
        float: left;
        padding-right: 10px;
    }

    .texto-caja {
        font-size: 15px;
        line-height: 20px;
        letter-spacing: -0.03em;
        font-family: "Poppins", sans-serif !important;
    }

    .resaltar {
        color: orange;
    }
}

.biometria {
    text-align: center;
    padding-bottom: 20px;
}

.card-biometria {
    color: #333333;
    padding: 10px 0 0 10px;
    font-family: "Poppins", sans-serif !important;
}