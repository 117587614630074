$color: #70c000;

.pointer {
    cursor: pointer;
}

.personal {
    .container-data {
        background-color: #fff;
        border-radius: 12px;
        box-shadow: 0 4px 8px rgba(108, 108, 108, 0.1);
        margin: 20px auto;
        overflow: hidden;
        position: relative;
        transition: transform 0.3s, box-shadow 0.3s;
        box-sizing: border-box;

        .container-data-type {
            padding: 20px;
            border-left: 8px solid $color;
            position: relative;
            margin-bottom: 0;
            box-sizing: border-box;
        }
    }
}

.table {
    font-size: small;
    border-collapse: collapse;
    font-family: sans-serif;
}

/* Estilos para modo mobile */
@media (max-width: 576px) {

    .list-view {
        display: block;
    }

    .list-item {
        border-bottom: 1px solid #ddd;
        padding: 10px;
        margin-bottom: 10px;
    }

    .list-header {
        font-weight: bold;
        margin-bottom: 5px;
    }

    .actions {
        margin-top: 10px;
    }

    .actions a {
        margin-right: 10px;
    }

    .selected {
        background-color: #f0f8ff;
    }
}