$white-color: #ffffff;
$border-radius: 5px;
$padding: 10px;

.collapse-accordion {
  background-color: $white-color !important;
  box-sizing: border-box !important;
  border-radius: $border-radius !important;
  padding: $padding !important;
  border: 0 !important;

  .card-header {
    background-color: $white-color;
    text-align: left;
  }

  .text-right {
    text-align: right;
  }
}

.text-small {
  font-size: 12px;
}