.ribbon {
    .text-gray {
        color: #5B5B5B;
        font-weight: 700;
        letter-spacing: -0.03em;
        font-family: 'Poppins', sans-serif !important;
        margin-top: 15px;
        font-size: 28px;
    }

    .steps {
        background-color: white;
        padding: 10px;
        text-align: center;
        margin-bottom: 20px;
    }

    .banner {
        background-color: #C6E8F2;
        color: #5B5B5B;
        padding: 20px;
        font-size: 20px;
        text-align: center;
    }
}