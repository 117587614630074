.loading {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    background: #373737e6;
    width: 100%;
    height: 100vh;
    position: fixed;
    z-index: 10000;
}